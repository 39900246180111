import React from 'react'

const KnowledgeManagement = () => {
  return (
    <div className='knowledge flex justify-center items-center flex-col xl:pl-20 px-4'>
      <div className='separator w-full flex justify-center items-center gap-4'>
        <span />
          Knowledge Management
        <span />
      </div>
      <div className='about_us_content w-full px-8 xl:flex xl:justify-between gap-20 '>
        <div className='about_us_text text-slate-700 flex flex-col justify-center items-center xl:items-start'>
          <p className='text-4xl font-regular text-center my-8 xl:text-6xl xl:text-left xl:leading-tight xl:ml-0'>ikb.sa <span className='font-extrabold '>Knowledge Management</span></p>
          <p className='text-center xl:text-left xl:text-xl'>Rebuild the digital platform with the agent in mind to maximize productivity and time to resolve the issue.</p>
          <a href='/#' target='_blank'>
            <div className='btn mt-6 px-6 py-3 w-52 flex items-center justify-center hover:cursor-pointer'>
              <p className='font-medium'>More Details</p>
            </div>
          </a>
        </div>
        <div className='fluent_eng_image xl:w-3/4 mt-10'>
          <img src={require('../assets/kb.png')} alt='ticketing' />
        </div>
      </div>
    </div>
  )
}

export default KnowledgeManagement