import React, { useState } from 'react'

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='navbar xl:px-10 xl:py-4 px-4 py-2'>
        <div className='logo'>
            <a href='/#'>
                <img src={require('../assets/logo.png')} alt='logo' />    
            </a>
        </div>
        <div className={`nav-items ${isOpen && "open"}`}>
            <a href='#Home'>Home</a>
            <a href='#About'>About Us</a>
            <a href='#Fluent'>Fluent Engagement</a>
            <a href='#Ticketing'>Ticketing System</a>
            <a href='#Knowledge'>Knowledge Management</a>
            <a href='#Contact'>Contact Us</a>
        </div>
        <div className={`nav-toggle ${isOpen && "open"}`} onClick={() => setIsOpen(!isOpen)}>
            <div className='bar'></div>
        </div>
    </div>
  )
}

export default Navbar