import React from 'react'
import './main.css';


const AboutUs = () => {
  return (
    <div className='about-us flex justify-center items-center flex-col xl:px-20'>
      <div className='separator w-full flex justify-center items-center gap-4'>
        <span />
          About Us
        <span />
      </div>
      <div className='about_us_content w-full px-4 xl:flex xl:justify-between gap-20'>
        <div className='about_us_text text-slate-700 flex flex-col justify-center'>
          <p className='text-4xl font-regular text-center m-10 xl:text-6xl xl:text-left xl:leading-tight xl:ml-0 leading-tight'>Why you Should Choose <span className='font-extrabold '>Alt. Solutions</span></p>
          <p className='text-center xl:text-left xl:text-xl'>At Alt. Solutions, we leverage over 15 years of collective team experience in the contact center industry to deliver unparalleled improvements in key performance indicators (KPIs). Our expertise allows us to enhance the quality of your contact center operations while simultaneously reducing costs.</p>
        </div>
        <div className='about_us_image xl:w-3/4 mt-10'>
          <img src={require('../assets/aboutus.png')} alt='aboutus' />
        </div>
      </div>
    </div>
  )
}

export default AboutUs