import React from 'react';
import './main.css';

const Footer = () => {
  return (
    <div className='py-2 px-20'>
      <div className='footer w-full xl:flex-row flex justify-between text-l py-10 border-b-2 flex-col gap-8'>
      <div className='logo_desc'>
        <img className='xl:w-20 w-28' src={require('../assets/logo.png')} alt='logo' />
        <p className=' text-slate-500 pt-3 w-80'>Our mission to provide best business solutions and turn basic ideas into professional tools</p>
      </div>
      <div className='footer_links flex xl:justify-between w-2/4 xl:flex-row flex-col gap-8'>
        <div className='links flex flex-col gap-2'>
          <div className='title font-extrabold'>Links</div>
            <a href='/#' target='_blank'>About us</a>
            <a href='/#' target='_blank'>Our Mission</a>
            <a href='/#' target='_blank'>Request Quote</a>
            <a href='/#' target='_blank'>Self Help</a>
        </div>
        <div className='links flex flex-col gap-2'>
          <div className='title font-extrabold'>Our Projects</div>
            <a href='/#' target='_blank'>Fluent Engagement</a>
            <a href='/#' target='_blank'>Ticketing System</a>
            <a href='/#' target='_blank'>Knowledge Management</a>
        </div>
        <div className='links flex flex-col gap-2'>
          <div className='title font-extrabold'>Get in Touch</div>
            <a href='mailto:info@alt.sa' target='_blank'>info@alt.sa</a>
            <a href='https://wa.me/+966508808884' target='_blank'>+966 5088 08884</a>
        </div>
      </div>
    </div>
    <footer class="flex justify-center items-center">
      <div class='sub_footer flex gap-2 content-center items-center py-5 text-xs'>
        <p class="text-center">All rights reserved. Designed with</p>
        <img class='w-auto' src={require('../assets/heart.png')} alt='heart' />
        <p class="text-center">in alt.sa</p>
      </div>
    </footer>

    </div>
    
  )
}

export default Footer