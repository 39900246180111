import React from 'react'

const ContactUs = () => {
  return (
    <div className='contact flex justify-center items-center flex-col xl:pl-20 px-4 w-full pb-10'>
      <div className='separator w-full flex justify-center items-center gap-4'>
        <span />
        Contact Us
        <span />
      </div>
      <div className='about_us_content w-full px-8 xl:flex gap-20 mt-10'>
        <div className='about_us_text text-slate-700 flex flex-col justify-center items-center xl:items-start xl:w-7/12'>
          <p className='text-4xl font-regular text-center my-8 xl:text-6xl xl:text-left xl:leading-tight xl:ml-0'>Don’t Hesitate to <span className='font-extrabold '>Contact Us</span></p>
          <p className='text-center xl:text-left xl:text-xl'>Send us your cooperation message at any time. We will do our best to get the answer back to your email as soon as possible. </p>
        </div>
        <div className="flex justify-center items-center w-full xl:pt-0 pt-8 z-auto	">
            <iframe
            title="Embedded Content"
            className="border-none rounded-lg drop-shadow-2xl bg-white"
            style={{ width: "450px", height: "600px", overflow: "scroll",}}
            src="https://bot-view.fluent.sh/customer-support-copy-e4c4xi1"
            ></iframe>
      </div>
      </div>
    </div>
  )
}

export default ContactUs