import React from 'react';

const Interrested = () => {
  return (
    <div className='interrested xl:px-20 w-full px-8 flex justify-center items-center'>
      <div className='with_us rounded-3xl xl:px-20 p-12 xl:flex gap-20 mt-10 relative w-full'>
        <div className='about_us_text text-slate-700 flex flex-col justify-center items-center xl:items-start xl:w-7/12'>
          <p className='text-4xl font-regular text-center my-8 xl:text-6xl xl:text-left xl:leading-tight xl:ml-0'>Interested to Work <span className='font-extrabold '>With Us?</span></p>
          <div className='flex flex-col xl:flex-row justify-center items-center xl:gap-10 gap-4'>
            <a href='/#' target='_blank'>
              <div className='btn px-6 py-3 w-52 flex items-center justify-center hover:cursor-pointer'>
                <p className='font-medium'>Get Free Quote</p>
              </div>
            </a>
            <span>OR</span>
            <a href='/#' target='_blank'>
              <div className='btn px-6 py-3 w-52 flex items-center justify-center hover:cursor-pointer'>
                <p className='font-medium'>+966 5088 08884</p>
              </div>
            </a>
          </div>
        </div>
        <div className='fluent_eng_image mt-auto xl:mt-0 absolute bottom-0 right-20 transform hidden xl:block'>
          <img src={require('../assets/man.png')} alt='man' />
        </div>
      </div> 
    </div>
  );
};

export default Interrested;
