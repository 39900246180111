import React from 'react';
import './main.css';
import { easeIn, easeInOut, motion } from "framer-motion"


const Home = () => {
  return (
    <>
        <div className='home xl:px-20 xl:flex-row px-8 flex-col'>
        <div className='intro xl:items-start items-center'>
          <div className='welcome'>
            <img src={require('../assets/Claps.png')} alt='Claps' />  
            <p className='text-lg text-slate-700'>Welcome to Alt. Solutions</p>
          </div>
            <p className='xl:text-6xl text-slate-700 font-extrabold text-4xl text-center xl:text-start'>One Place for all your customer service  needs</p>
            <p className='text-xl text-slate-700 text-center xl:text-start'>Alt is a startup company specialized in developing contact center system</p>
        </div>
        <div className='header'>
          <img src={require('../assets/header.png')} alt='header' />  
        </div>
      </div>
      <motion.div 
      animate={{ translateY: [0, -10, 0] }} // Animate translateY property to create a jumping effect
      transition={{ repeat: Infinity, duration: 1.2, ease: easeInOut }}
      className='scroll'>
        <img src={require('../assets/arrow-down.png')} alt='arrow' />  
        <p>Scroll down for more</p>
      </motion.div>
      <div className='scroll-up'>
        <a href='/#'>
        <img src={require('../assets/scroll-up.png')} alt='scroll-up' />  
        </a>
      </div>
    </>

  )
}

export default Home