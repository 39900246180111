import './App.css';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import FluentEngagement from './components/FluentEngagement';
import TickectingSystem from './components/TickectingSystem';
import KnowledgeManagement from './components/KnowledgeManagement';
import ContactUs from './components/ContactUs';
import Navbar from './components/Navbar';
import Interrested from './components/Interrested';
import Footer from './components/Footer';

function App() {
  return (
    <div className='bg w-full'>
      <Navbar />
      <div id='Home'> <Home /> </div>
      <div id='About'> <AboutUs /> </div>
      <div id='Fluent'> <FluentEngagement /> </div>
      <div id='Ticketing'> <TickectingSystem /> </div>
      <div id='Knowledge'> <KnowledgeManagement /> </div>
      <div id='Contact'> <ContactUs /> </div>
      <div id='Interrested'> <Interrested /> </div>
      <div id='Footer'> <Footer /> </div>
    </div>
  );
}

export default App;
